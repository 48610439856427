export default [
  {
    action: 'bold',
    icon: 'fas fa-bold',
    method: 'toggleBold',
  },
  {
    action: 'italic',
    icon: 'fas fa-italic',
    method: 'toggleItalic',
  },
  {
    action: 'underline',
    icon: 'fas fa-underline',
    method: 'toggleUnderline',
  },
  {
    action: 'strike',
    icon: 'fas fa-strikethrough',
    method: 'toggleStrike',
  },
  {
    action: 'bulletList',
    icon: 'fas fa-list-ul',
    method: 'toggleBulletList',
  },
  {
    action: 'orderedList',
    icon: 'fas fa-list-ol',
    method: 'toggleOrderedList',
  },
];
